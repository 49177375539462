import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Page } from '@commonTuna/react/objects/Page';

import { getPageInfo, getPageShortName } from '@app/components/Utils';

interface Props {
	items?: Array<Page>;
}

const BlogItem: React.FC<{item: Page}> = ({ item }) => {
	const info = item.langs && getPageInfo(item);
	const history = useHistory();

	return <div className="article clearfix" key={item.id}>
		<Link to={`/${item.fullPath}`} className="post"><h3 className="post__title">{getPageShortName(item)}</h3></Link>
		<div className="external-html" dangerouslySetInnerHTML={{ __html: info ? info.shortBody : '' }} />
		<button
			type="button"
			className="article__link pull-right"
			onClick={() => history.push(`/${item.fullPath}`)}
		>
			Read more
		</button>
	</div>;
};

const Blog:React.FC<Props> = ({ items }) => {
	if (!items) {
		return null;
	}

	return <div className="content-block">
		<div className="extra-text-block" id="blog" style={{ margin: 0, paddingBottom: 0 }}>
			<h2>
				Blog
			</h2>
			<h3 className="text-center is-relative title__tagline">
				If you have knowledge
				{' '}
				<span className="separator">-</span>
				{' '}
				Let others light their candles in it
				<i className="tagline">Margaret Fuller</i>
			</h3>
		</div>
		{items.map((item) => <BlogItem item={item} key={item.id} />)}
	</div>;
};

export default Blog;
