import { getMinutesFromDuration } from '@common/react/utils/timeUtils';

import { WorkingHour } from '@commonTuna/react/objects/BaseLocation';

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const daysEs = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

export const solveWorkingHours = (workingHours: Array<WorkingHour>) => {
	const whs = workingHours.slice()
		.sort((a, b) => {
			if (a.dayOfWeek === 0) return 1;
			if (b.dayOfWeek === 0) return -1;
			return a.dayOfWeek - b.dayOfWeek;
		});
	const objByDayOfWeek: { [key: string]: WorkingHour } = {};
	whs.forEach((wh) => {
		if (!objByDayOfWeek[wh.dayOfWeek]) {
			objByDayOfWeek[wh.dayOfWeek] = wh;
		} else {
			objByDayOfWeek[wh.dayOfWeek] = {
				...wh,
				endTime: getMinutesFromDuration(objByDayOfWeek[wh.dayOfWeek].endTime) < getMinutesFromDuration(wh.endTime)
					? wh.endTime : objByDayOfWeek[wh.dayOfWeek].endTime,
				startTime: getMinutesFromDuration(objByDayOfWeek[wh.dayOfWeek].startTime) < getMinutesFromDuration(wh.startTime)
					? objByDayOfWeek[wh.dayOfWeek].startTime : wh.startTime,
			};
		}
	});
	const obj: any = {};
	(Object.values(objByDayOfWeek)).forEach((wh) => {
		const key = `${wh.startTime}-${wh.endTime}`;
		if (!obj[key]) {
			obj[key] = [{ ...wh }];
		} else if (!obj[key].some((item) => item.dayOfWeek === wh.dayOfWeek)) {
			obj[key] = obj[key].concat(wh);
		}
	});
	return Object.values(obj)
		.map((arr: any, i) => {
			let title = days[arr[0].dayOfWeek];
			let titleEs = daysEs[arr[0].dayOfWeek];
			for (let i = 1; i < arr.length; i++) {
				const isEnd = arr[i].dayOfWeek === 6 && arr[i + 1]?.dayOfWeek === 0;
				if (arr[i].dayOfWeek - 1 !== arr[i - 1]?.dayOfWeek && !isEnd && !(arr[i].dayOfWeek === 0 && arr[i - 1]?.dayOfWeek === 6)) {
					title = `${title}, ${days[arr[i].dayOfWeek]}`;
					titleEs = `${titleEs}, ${daysEs[arr[i].dayOfWeek]}`;
				} else if (i + 1 === arr.length
					|| (arr[i].dayOfWeek + 1 !== arr[i + 1]?.dayOfWeek && !isEnd)) {
					title = `${title} - ${days[arr[i].dayOfWeek]}`;
					titleEs = `${titleEs} - ${daysEs[arr[i].dayOfWeek]}`;
				}
			}
			return {
				id: i,
				title,
				titleEs,
				startTime: arr[0].startTime,
				endTime: arr[0].endTime,
			};
		});
};
