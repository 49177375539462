import React from 'react';

import dummyAvatar from '@common/react/images/avatar.jpg';
import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

import { DoctorRatingInfo } from '@app/objects/Doctor';

interface Props {
	name: string;
	avatar: string;
	description: string;
	ratingInfo: DoctorRatingInfo;
}

const ProviderInfo: React.FC<Props> = ({
	name, avatar, description, ratingInfo,
}) => {
	const { ratingValue, reviewCount } = ratingInfo;
	return (
		<div className="provider-info clearfix">
			<div className="pull-left avatar-container">
				<ImageLazy
					className="avatar"
					height="100%"
					width="100%"
					alt="avatar"
					title={name}
					src={avatar ? `/remote/${avatar}` : dummyAvatar}
				/>
			</div>
			<div>
				<h2>
					{name}
					{typeof ratingValue !== 'undefined' && <div className="avg-rate">
						<span title="rate" className="stars ml10">
							{Array.from({ length: Math.round(+ratingValue || 0) })
								.map((_, i) => <i className="fa fa-star" key={i} aria-hidden="true" />)}
						</span>
						<span title="review count" className="review-count">
							(
							{+(reviewCount ?? 0)}
							)
						</span>
					</div>}
				</h2>
				<div dangerouslySetInnerHTML={{ __html: description }} />
			</div>
		</div>
	);
};

export default ProviderInfo;
