import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { PageItemState } from '@common/react/store/PageItem';
import ServerPageProvider from '@common/react/components/Core/ServerPageProvider/ServerPageProvider';
import { List } from '@common/typescript/objects/List';

import { PageImage } from '@commonTuna/react/objects/PageImage';
import { Review } from '@commonTuna/react/objects/Review';
import { Faq } from '@commonTuna/react/objects/Faq';
import { Page } from '@commonTuna/react/objects/Page';

import ProviderInfo from '@app/components/Pages/Home/ProviderInfo';
import Reviews from '@app/components/Pages/Home/Reviews';
import HomeForm from '@app/components/Pages/Home/HomeForm';

import FAQ from '@app/components/Pages/Home/FAQ';
import SpecialsBlock from '@app/components/Pages/Home/Specials';
import { HomePage } from '@app/objects/pages/HomePage';
import Contacts from '@app/components/Pages/Home/Contacts';
import Inquiry from '@app/components/UI/Inquiry/Inquiry';
import { Doctor } from '@app/objects/Doctor';
import { ApplicationState } from '@app/store';
import Blog from '@app/components/Pages/Home/Blog';

import '@app/scss/swiper.scss';

interface Props {
	home: PageItemState<HomePage>;
}

const HomeInner: React.FC<Props> = ({
	home,
}) => {
	let doctor : Doctor | null = null;
	let faqsInfo: Array<Faq> | null = null;
	let reviews: List<Review> | null = null;
	let articles: Array<Page> = [];
	let pageImages: Array<PageImage> = [];

	if (home.page) {
		const item = home.page.item;

		doctor = item.doctor;
		faqsInfo = item.faqsInfo;
		reviews = item.reviews;
		pageImages = item.pageImages || [];
		articles = item.articles || [];
	}

	const { blogPageId, location } = useSelector((state: ApplicationState) => ({
		blogPageId: state.blogPageId.item,
		location: state.location.item,
	}));

	return <>
		<main>
			<div className="top-banner" id="home">
				<div className="image-block">
					<picture>
						<source media="(max-width: 600px)" srcSet="/images/Face_mobile_small.webp" />
						<source media="(max-width: 768px)" srcSet="/images/Face_mobile.webp" />
						<source media="(min-width: 769px)" srcSet="/images/Face.webp" />
						<img className="top-banner__image" alt="banner" srcSet="/images/Face.webp" />
					</picture>
					<div className="content-block">
						<div className="banner-text">
							<h1>{doctor ? doctor.nameEn : '-'}</h1>
						</div>
						<div className="inquiry-form hide-mobile">
							<HomeForm />
						</div>
					</div>
				</div>
			</div>
			{doctor
				&& <div className="content-block doctor-info" id="info">
					<ProviderInfo
						name={doctor.nameEn}
						avatar={doctor.originalAvatar}
						description={doctor.descriptionEn}
						ratingInfo={doctor.doctorRatingInfo}
					/>
				</div>
			}
			<div className="show-mobile container">
				<div className="extra-text-block show-mobile" id="booking" style={{ margin: 0 }}>
					<h2>
						Schedule
					</h2>
					<h3 className="text-center">
						We will get in touch with you as soon as possible
					</h3>
				</div>
				<Inquiry idPrefix="contact-mobile-" hideTitle className="inquiry-wrapper-mobile" />
			</div>
			<div className="extra-text-block" id="services" style={{ margin: 0, paddingBottom: 0 }}>
				<h2>
					Services
				</h2>
				<h3 className="text-center">
					Providing high quality services
					{' '}
					<span className="separator">-</span>
					{' '}
					Our main priority
				</h3>
			</div>
			{pageImages?.length > 0
				&& <div id="specials" style={{ width: '100%' }}>
					<SpecialsBlock pageImages={pageImages} />
				</div>
			}
			{blogPageId > 0 && articles.length > 0
				? <>
					<Blog items={articles} />
					<div className="extra-text-block text-center" style={{ margin: 0, paddingBottom: 10 }}>
						<Link
							to="/blog"
							type="button"
							className="more-button more-button__big"
						>
							More Sharell Marlitz Articles
						</Link>
					</div>
				</> : null}
			<div className="content-block" id="faq">
				<div className="extra-text-block" style={{ margin: 0, paddingBottom: 0 }}>
					<h2>
						FAQ
					</h2>
					<h3 className="text-center">
						Frequently asked questions
						{' '}
						<span className="separator">-</span>
						{' '}
						Everything you need to know
					</h3>
				</div>
				{faqsInfo !== null
					&& <FAQ faqs={faqsInfo} />
				}
			</div>
			<div className="content-block" id="reviews">
				<div className="extra-text-block" style={{ margin: 0, paddingBottom: 0 }}>
					<h2>
						Reviews
					</h2>
					<h3 className="text-center">
						What our clients says
						{' '}
						<span className="separator">-</span>
						{' '}
						FNP Sharell Marlitz reviews
					</h3>
				</div>
				<Reviews reviews={reviews} />
			</div>
			{location && <div className="content-block contacts" id="contacts">
				<div className="extra-text-block" style={{ margin: 0, paddingBottom: 10 }}>
					<h2>
						Contacts
					</h2>
					<h3 className="text-center">
						We’d love to help you
						{' '}
						<span className="separator">-</span>
						{' '}
						Reach out to us
					</h3>
				</div>
				<div className="contacts__content">
					<Contacts location={location} />
					<Inquiry idPrefix="contact-" className="hide-mobile" />
				</div>
			</div>}
		</main>
	</>;
};

const Home: React.FC = () => {
	return (
		<ServerPageProvider
			path="home"
			inner={(page) => (<HomeInner home={{ page } as any} />)}
		/>
	);
};

export default Home;
